import React from "react";
import { UILoaderProps } from "./UILoaderProps";
import { Backdrop, Typography, Stack, Box } from "@mui/material";
import colors from "@/utils/colorsV2";
import SmileGif from "@/assets/images/smile.gif";
import SmileImage from "@/assets/images/smile.svg";
import Image from "next/image";
import "./UILoader.css";
import UIProgress from "../progress/UIProgress";
import radius from "@/utils/radius";
import UIText from "../text/UIText";
type StatusLoader = "loading" | "success" | "finished" | "error";

const UILoader: React.FC<UILoaderProps> = ({
  open,
  loaderText,
  loaderTextTitle = "Espera un momento, por favor",
  percentage,
  isSlow,
}) => {
  const styles = {
    zIndex: (theme: any) => theme.zIndex.drawer + 9999,
    alignItems: "center",
    color: "#fff",
    backgroundColor: "#10255480",
    backdropFilter: "blur(8px)",
  };

  const [openLoader, setOpenLoader] = React.useState(open);
  const [statusLoader, setStatusLoader] = React.useState<StatusLoader>(
    open ? "loading" : "finished"
  );
  React.useEffect(() => {
    if (open) {
      setOpenLoader(open);
      setStatusLoader("loading");
    } else {
      setStatusLoader("success");
      setTimeout(() => {
        setStatusLoader("finished");
        setOpenLoader(false);
      }, 2000);
    }
  }, [open]);
  return (
    <Backdrop sx={styles} open={openLoader}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          backgroundColor: colors["blue-1"],
          borderRadius: radius.xl,
          maxWidth: "480px",
          padding: "32px",
        }}
        padding={1}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              minWidth: "100px",
              minHeight: "100px",
              borderRadius: "8px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {statusLoader === "loading" ? (
              <Image
                src={SmileGif.src}
                alt="Cargando..."
                width={100}
                height={100}
                className="image-transition"
              />
            ) : statusLoader === "error" ? (
              <></>
            ) : (
              <SmileImage
                alt="Cargando..."
                width={100}
                height={100}
                className="image-transition"
              />
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UIText
              text={loaderTextTitle}
              color={"white"}
              weight={"semibold"}
            />
            <UIText text={loaderText} color={"white"} weight={"semibold"} />
          </Box>
        </Box>

        {isSlow && (
          <Box marginTop={2} marginBottom={-2} width={"100%"}>
            <UIText
              text="Este proceso tomará unos minutos, no cierres esta ventana"
              color={"white"}
              weight={"medium"}
              size="text-s"
            />
          </Box>
        )}
        
        {percentage !== undefined && percentage >= 0 && (
          <Box marginTop={2} width={"100%"}>
            <UIProgress type="linear" percentage={percentage} />
          </Box>
        )}
      </Stack>
    </Backdrop>
  );
};

export default UILoader;
