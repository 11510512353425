export const iconName = {
  arrowDown: "chevron-down",
  arrowLeft: "chevron-left",
  arrowRight: "chevron-right",
  arrowUp: "chevron-up",
  bell: "bell",
  building: "building",
  calendar: "calendar",
  check: "check",
  close: "x-close",
  facebook: "facebook",
  file: "file",
  funnel: "filter-funnel",
  globe: "globe",
  google: "google",
  home: "home",
  homeLine: "home-line",
  help: "help",
  mail: "mail",
  message: "message-question-square",
  menu: "menu",
  phone: "phone",
  plus: "plus-circle",
  search: "search-sm",
  searchl: "search-lg",
  user: "user",
  whatsapp: "icon-whatsapp",
  mexico: "MX", 
  spain: "ES"
};


export type IconName = typeof iconName[keyof typeof iconName];
export default iconName;
