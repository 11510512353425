import { FunctionComponent, useMemo } from "react";
import UIButtonProps from "./UIButtonProps";
import { Button, ButtonProps, styled } from "@mui/material";
import colors from "@/utils/colorsV2";
import radius from "@/utils/radius";
import UIText from "../text/UIText";
import React from "react";

const defaultColor = "info";
const defaultSize = "full";
const defaultDisabled = false;
const defaultTransparency = "40";

const colorStyles = (disabled: boolean) => ({
  primary: {
    backgroundColor: `${colors.asertaBlue}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.black,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.asertaBlue}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.black,
    },
  },
  secondary: {
    backgroundColor: disabled ? `${colors.grayDM100}${defaultTransparency}` : colors.white,
    color: disabled ? `${colors.grayDM500}` : colors.black,
    border: disabled ? `1px solid ${colors.grayDM300}${defaultTransparency}` : `1px solid ${colors.asertaBlue}`,
    "&:hover": {
      border: disabled ? `1px solid ${colors.grayDM300}${defaultTransparency}` : `1px solid ${colors.asertaBlue}`,
      backgroundColor: disabled ? `${colors.grayDM100}${defaultTransparency}` : colors.grayDM100,
      color: disabled ? `${colors.grayDM500}` : colors.black,
    },
  },
  info: {
    backgroundColor: `${colors.grayDM500}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.black,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.grayDM500}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.black,
    },
  },
  warning: {
    backgroundColor: `${colors.warning500}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.white,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      border: `1px solid ${colors.grayDM500}`,
      backgroundColor: `${colors.warning200}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.white,
    },
  },
  success: {
    backgroundColor: `${colors.success500}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.white,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.success500}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.white,
      border: `1px solid ${colors.grayLM500}`,
    },
  },
  error: {
    backgroundColor: `${colors.error500}${disabled ? defaultTransparency : ""}`,
    color: colors.white,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.error500}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.white,
      border: `1px solid ${colors.grayLM500}`,
    },
  },
  white: {
    backgroundColor: `${colors.white}${disabled ? defaultTransparency : ""}`,
    color: colors.black,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.white}${disabled ? defaultTransparency : ""}`,
      color: colors.black,
      border: `1px solid ${colors.grayLM500}`,
    },
  },
  black: {
    backgroundColor: `${colors.black}${disabled ? defaultTransparency : ""}`,
    color: colors.white,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.black}${disabled ? defaultTransparency : ""}`,
      color: colors.white,
      border: `1px solid ${colors.grayLM500}`,
    },
  },
  transparent: {
    backgroundColor: `${colors.transparent}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.asertaBlueGray,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.grayLM50}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.asertaBlue,
      border: `1px solid ${colors.grayLM300}`,
    },
  },
  gray: {
    backgroundColor: `${colors.asertaBlueGray}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.black,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.asertaBlueGray}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.black,
      border: `1px solid ${colors.grayLM500}`,
    },
  },
  orange: {
    backgroundColor: `${colors.asertaOrange}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.white,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.warning500}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.white,
      //border: `1px solid ${colors.grayLM500}`,
    },
  },
  blue: {
    backgroundColor: `${colors.asertaBlue}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.white,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.asertaBlue}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.white,
      border: `1px solid ${colors.grayLM500}`,
    },
  },
  blueGray: {
    backgroundColor: `${colors.asertaBlueGray}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.white,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.asertaBlue}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.white,
      border: `1px solid ${colors.grayLM500}`,
    },
  },
  outlineOrange: {
    backgroundColor: colors.white,
    color: disabled ? colors.grayLM500 : colors.asertaOrange,
    border: `1px solid ${disabled ? colors.grayLM300 : colors.asertaOrange}`,
    boxShadow: disabled ? 'none' : '0px 1px 2px rgba(16, 24, 40, 0.05)',
    "&:hover": {
      backgroundColor: disabled ? colors.white : `${colors.asertaOrange}10`,
      color: disabled ? colors.grayLM500 : colors.asertaOrange,
      border: `1px solid ${disabled ? colors.grayLM300 : colors.asertaOrange}`,
      boxShadow: disabled ? 'none' : '0px 2px 4px rgba(16, 24, 40, 0.1)',
    },
  },
  torange: {
    backgroundColor: `${colors.transparent}${
      disabled ? defaultTransparency : ""
    }`,
    color: colors.asertaOrange,
    border: `1px solid ${colors.transparent}`,
    "&:hover": {
      backgroundColor: `${colors.grayLM50}${
        disabled ? defaultTransparency : ""
      }`,
      color: colors.asertaOrange,
      border: `1px solid ${colors.grayLM300}`,
    },
  },
});

const sizeStyles = {
  small: {
    height: "36px",
    width: "36px",
  },
  medium: {
    height: "40px",
    width: "158px",
  },
  large: {
    height: "48px",
    width: "181px",
  },
  xLarge: {
    height: "48px",
    width: "202px",
  },
  full: {
    height: "48px",
    minWidth: "fit-content",
  },
};

interface ITextColors {
  [k: string]: UIButtonProps["color"];
}

const textColors: ITextColors = {
  primary: "white",
  secondary: "blue",
  info: "black",
  warning: "white",
  success: "white",
  error: "white",
  white: "black",
  black: "white",
  transparent: "secondary",
  gray: "white",
  orange: "white",
  blue: "white",
  blueGray: "blueGray",
  undefined: "black",
  outlineOrange: "orange",
  torange: "orange",
};

interface INewButtonProps extends UIButtonProps {
  _color: UIButtonProps["color"];
  _size: UIButtonProps["size"];
}

const StyledButton = styled(Button)<INewButtonProps>(
  ({
    _color = defaultColor,
    _size = defaultSize,
    disabled = defaultDisabled,
    fullWidth,
    theme,
  }) => {
    const colorStyle = colorStyles(disabled)[_color];
    const sizeStyle = sizeStyles[_size];

    return {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      borderRadius: radius.md,
      padding: "16px 24px",
      textTransform: "none",
      transition: theme.transitions.create(
        ["background-color", "box-shadow", "border-color", "color"],
        {
          duration: theme.transitions.duration.short,
        }
      ),
      ...colorStyle,
      ...sizeStyle,
      ...(fullWidth && { width: "100%" }),
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
        minWidth: "auto",
        width: _size === "small" ? "36px" : "40px",
        height: _size === "small" ? "36px" : "40px",
      },
    };
  }
);

const IconWrapper = styled("span")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const TextWrapper = styled("span")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));


const UIButton: React.FC<UIButtonProps> = React.memo(
  ({
    color = defaultColor,
    size = defaultSize,
    label,
    onClick,
    disabled = defaultDisabled,
    fullWidth = false,
    sx,
    startIcon,
    endIcon,
  }) => {
    const textColor = useMemo(
      () => (color && textColors[color]) || "primary",
      [color]
    );

    const buttonContent = useMemo(
      () => (
        <>
          {startIcon && <IconWrapper>{startIcon}</IconWrapper>}
          <TextWrapper>
            <UIText text={label} color={textColor} />
          </TextWrapper>
          {endIcon && <IconWrapper>{endIcon}</IconWrapper>}
        </>
      ),
      [startIcon, label, textColor, endIcon]
    );

    return (
      <StyledButton
        _color={color}
        _size={size}
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={onClick}
        sx={sx}
      >
        {buttonContent}
      </StyledButton>
    );
  }
);

UIButton.displayName = "UIButton";

export default UIButton;
