
export enum CompanyName {
    ASERTA = "Aserta",
    INSURGENTES = "Insurgentes"
}

export const isAserta = (empresa: string | CompanyName): boolean => {
    return empresa.toLowerCase() === CompanyName.ASERTA.toLowerCase();
  };
  
  export const isInsurgentes = (empresa: string | CompanyName): boolean => {
    return empresa.toLowerCase() === CompanyName.INSURGENTES.toLowerCase();
  };

export const getValueForAserta = ( empresa: string | CompanyName, value?: string): string => {
    return empresa.toLowerCase() === CompanyName.ASERTA.toLowerCase() ? value ?? "" : "";
  };

  export const getValueForInsurgentes = ( empresa: string | CompanyName, value?: string): string => {
    return empresa.toLowerCase() === CompanyName.INSURGENTES.toLowerCase() ? value ?? "" : "";
  };