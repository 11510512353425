"use client"
import * as React from 'react';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '@/theme';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { InfoFiadoProvider } from '@/context/infoFiadoContext';
import { SessionProvider, signIn, signOut, useSession } from "next-auth/react";
import './globals.css';
import { CurrentSessionProvider } from '@/context/currentSessionContext';
import { initializeLogCollector } from '@/utils/logCollector';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import { EmissionProvider } from '@/context/emissionContext';
import { useEffect, useState } from 'react';
import ModalProvider from 'mui-modal-provider';

const SnackbarAction: React.FC<{ key: SnackbarKey }> = ({ key }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon sx={{ height: 20, width: 20 }} />
    </IconButton>
  );
};

let isScriptLoaded = false;

export default function RootLayout(props: { children: React.ReactNode }) {
  const [scriptLoaded, setScriptLoaded] = useState(isScriptLoaded);

  useEffect(() => {
    if (!isScriptLoaded) {
      const script = document.createElement('script');
      script.src = 'https://atom-plugin-io.web.app/atom-plugin-web.js';
      script.async = true;
      script.onload = () => {
        isScriptLoaded = true;
        setScriptLoaded(true);
      };

      document.body.appendChild(script);

      return () => {
        // No eliminamos el script para mantener la persistencia
        script.onload = null;
      };
    } else {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    

  }, []);

  useEffect(() => {
    const checkChatLoaded = setInterval(() => {
      const atomPlugin = document.querySelector('atom-plugin-web'); 
      if (atomPlugin && atomPlugin.shadowRoot) {
        var style = document.createElement('style')
        style.innerHTML = '.messenger-button{visibility: hidden;} .button-display{margin-bottom: 135px; margin-left:160px;}'
        atomPlugin.shadowRoot.appendChild(style)
        clearInterval(checkChatLoaded);
      }
    }, 500);
    return () => clearInterval(checkChatLoaded);
  }, []);

  return (

    <html lang="en">
      <body>
      {scriptLoaded && (
          <atom-plugin-web
            id="luv3Ki0btuTL6wgjlnp7"
            company="oiIR8mRAAlkNLkgp40I4"
            style={{ marginBottom: '150px' }}
            customchat='["position: bottom-left", "margin-bottom: 150px"]'
          />
        )}
      <CurrentSessionProvider >
        <AppRouterCacheProvider options={{ enableCssLayer: true }}>
          <ThemeProvider theme={theme}>
              <InfoFiadoProvider>
                <SessionProvider>
                  <EmissionProvider>
                    <CssBaseline />
                  <CssBaseline />
                  <SnackbarProvider maxSnack={3}
                    autoHideDuration={null}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    action={(key: SnackbarKey) => <SnackbarAction key={key} />}
                  >
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <ModalProvider>

                    {props.children}
                    
                    </ModalProvider>
                  </SnackbarProvider>
                  </EmissionProvider>
                </SessionProvider>
              </InfoFiadoProvider>
            </ThemeProvider>
          </AppRouterCacheProvider>
        </CurrentSessionProvider>

       
      </body>
    </html>
  );
}
