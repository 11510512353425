import { CurrentUsersResponse, User } from "@/services/get-current-user/getCurrentUserService";
import React, { createContext, useContext, useEffect, useState } from "react";

export interface UserDataInfo {
  login: string,
  email: string,
  username: string,
  agentId: string,
  agentType: string,
  agentName: string,
  raw: CurrentUsersResponse
  role: string
}
export interface CompanyInfo {
  name: string;
  subsidiary: Array<{
    subsidiary: string,
    subsidiaryId: string,
    agentId: string;
    agentPosition: string;
    agentPositionName: string;
    firmaDigital: string;
    corredorId: string;
    isDefault: boolean;
  }>;
  rol: string;
}
export interface SessionInfo {
  user?: UserDataInfo
  company?: Array<CompanyInfo>;
  userRaw?: User
  version?: string
  permissions?: Array<string>
}
interface CurrentSessionContextType {
  session: SessionInfo | undefined ;
  setSession: React.Dispatch<React.SetStateAction<SessionInfo | undefined>>;
}
const CurrentSessionContext = createContext<CurrentSessionContextType | undefined>(undefined);

interface PropsCurrentSessionProvider {
  children: React.ReactNode;
}
const key = "currentSession";
const CurrentSessionProvider: React.FC<PropsCurrentSessionProvider> = ({ children }) => {
  const [session, setSession] = useState<SessionInfo | undefined>(undefined);

  useEffect(() => {
    if (session === undefined) return;
    session.version = process.env.NEXT_PUBLIC_APP_VERSION;
    sessionStorage.setItem(key, JSON.stringify(session));
  }, [session]);

  useEffect(() => {
    const storedData = sessionStorage.getItem(key);

    if (storedData && storedData !== "undefined") {
      const storedObject = JSON.parse(storedData);
      if(storedObject?.version == process.env.NEXT_PUBLIC_APP_VERSION){
        setSession(JSON.parse(storedData));
      }else{
        setSession({});
      }
    }else{
      setSession({});
    }
  }, []);
  return <CurrentSessionContext.Provider value={{ session, setSession }}>{children}</CurrentSessionContext.Provider>;
};

const useCurrentSessionContext = () => {
  const context = useContext(CurrentSessionContext);
  if (context === undefined) {
    throw new Error("useCurrentSessionContext debe ser usado dentro de un CurrentSessionProvider");
  }
  return context;
};

export { CurrentSessionProvider, CurrentSessionContext, useCurrentSessionContext };
