import {
    GetAgentPermissionsRequest,
    GetAgentPermissionsResponse
} from "@/services/get-agent-permissions/getAgentPermissions";

export function useGetAgentPermissions() {
    const getPermissions = async (request: GetAgentPermissionsRequest) => {
        const result = await fetch(`/api/get-agent-permissions`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });
        const { data } = await result.json();
        return data as GetAgentPermissionsResponse;
    };

    return getPermissions;
}