import React, { createContext, useContext, useEffect, useState } from "react";

// Interfaces base con readonly para mayor seguridad
export interface Customer {
  readonly name: string;
  readonly number: string;
  readonly agent: string;
  readonly subsidiary: string;
  readonly registrationDate: string;
  readonly rfc: string;
  readonly personType: string;
}

export interface Agent {
  readonly agenteName: string;
  readonly idAgenteAserta: string;
  readonly idPuestoAgente?: string;
  readonly idAgente: string;
  readonly correoAgente: string;
  readonly sucursalAgente: string;
  readonly idSucursalAgente: string;
  readonly empresa: string;
}

export interface Policy {
  readonly number: string;
  readonly type: string;
  readonly status: string;
  readonly date: string;
  readonly amount: string;
}

export interface Emission {
  readonly customer: Customer;
  readonly agent: Agent;
  readonly policy: Policy;
}

// Tipo del contexto
interface EmissionContextType {
  data: Emission | undefined;
  setData: React.Dispatch<React.SetStateAction<Emission | undefined>>;
  clientIdEmission: string;
  setClientIdEmission: React.Dispatch<React.SetStateAction<string>>;
  getStoredDataByClientId: (clientId: string) => Emission | undefined;
  clearContext: () => void;
}

// Valor inicial del contexto
const initialContextValue: EmissionContextType = {
  data: undefined,
  setData: () => undefined,
  clientIdEmission: "",
  setClientIdEmission: () => "",
  getStoredDataByClientId: () => undefined,
  clearContext: () => undefined
};

const EmissionContext = createContext<EmissionContextType>(initialContextValue);

interface EmissionProviderProps {
  children: React.ReactNode;
}

export const EmissionProvider: React.FC<EmissionProviderProps> = ({ children }) => {
  const [data, setData] = useState<Emission | undefined>(undefined);
  const [clientIdEmission, setClientIdEmission] = useState("");

  // Persistencia en localStorage
  useEffect(() => {
    if (!data || !clientIdEmission) return;
    
    try {
      localStorage.setItem(clientIdEmission, JSON.stringify(data));
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  }, [data, clientIdEmission]);

  // Recuperación inicial de datos
  useEffect(() => {
    if (!clientIdEmission) return;
    
    try {
      const storedData = localStorage.getItem(clientIdEmission);
      if (storedData && storedData !== "undefined") {
        setData(JSON.parse(storedData));
      }
    } catch (error) {
      console.error('Error retrieving from localStorage:', error);
    }
  }, [clientIdEmission]);

  // Función para obtener datos por ID de cliente
  const getStoredDataByClientId = (clientId: string): Emission | undefined => {    
    if (!clientId) return undefined;

    try {
      const storedData = localStorage.getItem(clientId);      
      if (storedData && storedData !== "undefined") {
        return JSON.parse(storedData);
      }
    } catch (error) {
      console.error('Error retrieving client data:', error);
    }
    return undefined;
  };

  // Función para limpiar el contexto
  const clearContext = () => {
    setData(undefined);
    setClientIdEmission("");
  };

  const contextValue = {
    data,
    setData,
    clientIdEmission,
    setClientIdEmission,
    getStoredDataByClientId,
    clearContext
  };

  return (
    <EmissionContext.Provider value={contextValue}>
      {children}
    </EmissionContext.Provider>
  );
};

export const useEmissionContext = () => {
  const context = useContext(EmissionContext);
  if (!context) {
    throw new Error("useEmissionContext debe ser usado dentro de un EmissionProvider");
  }
  return context;
};