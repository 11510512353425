import { GetAgentsRequest, GetAgentsResponse } from "@/services/get-agents/getAgentsService";

export function useGetAgents() {
  const getAgents = async (req: GetAgentsRequest) => {
    const result = await fetch(`/api/get-agents`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data } = await result.json();
    const resultJson = data as GetAgentsResponse[];
    return resultJson;
  };
  return getAgents;
  
}
