import { MenuItem, FormControl, FormHelperText, Select, ListItemText, ListItemIcon } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UISelectProps from "./UISelectProps";
import colors from "@/utils/colors";
import { fontFamily_regular, sfUIDisplayLight, sfUIDisplayMedium } from "@/utils/fonts";
import UIIcon from "../../icons/UIIcon";
import { iconName } from '@/utils/icons';
import { useEffect, useState } from 'react';
import UiArrowIcon from '@/components/icons/UIArrowIcon';



const UISelect = ({ label, value, options, onChange, size, disabled }: UISelectProps) => {
  const [_value, setValue] = useState(value);
  const [optionsList, setOptionsList] = useState(options);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  return (
    <FormControl fullWidth variant="standard">

      <FormHelperText
        className={`label_form ${sfUIDisplayMedium.className}`}
        sx={{
        color: colors.gray700,
        fontFamily: sfUIDisplayLight,
        fontWeight: "500",
        fontSize: 14,
        marginBottom: "5px"
      }}>{label}</FormHelperText>
      <Select
      variant="outlined"
        IconComponent={(props) => (
          <div {...props} style={{ marginRight: '4px', display: "flex", alignItems: "center", transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)' }}>
            <UiArrowIcon />
          </div>
        )}

        value={_value}
        size={size}
        disabled={disabled}
        onChange={(event) => {
          setValue(event.target.value);
          if (onChange)
            onChange(event.target.value)
        }}
        displayEmpty
        className="label_form"

        sx={{
          height: "44px",
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primaryAserta,
            boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(249, 130, 7, 0.24);`,
          },
          "&& fieldset": {
            color: "#667085",
            fontFamily: "16px",
            fontWeight: "300",
            borderColor: colors.gray300,
            borderRadius: '8px',

          },
          "&& .MuiListItemText-primary": {
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
          "&& .MuiListItemIcon-root": {
            display: 'none'
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid",
              borderColor: colors.gray100,
            }
          }
        }}
        MenuProps={{
          slotProps: {
            paper: {
              sx: {
                fontWeight: "500",
                borderRadius: '8px',
                marginTop: '5px',
                border: "solid 1px #EAECF0",
                boxShadow: "none",
              }
            }
          },
          sx: {
            "&& .Mui-selected": {
              background: colors.gray50,
              borderRadius: '6px',
              margin:"0px 5px",
            },

          },
        }}
      >
        {
          optionsList?.map((option) => (
            <MenuItem key={option.id} value={option.value} sx={{ minWidth: "0px",       }}>
              <ListItemText primary={option.value} sx={{ my: 0 }} />
              {_value === option.value && <ListItemIcon sx={{ minWidth: "0px" }}>
                <UIIcon icon={iconName.check} size={20} color="#F98207" />
              </ListItemIcon>}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

export default UISelect;
