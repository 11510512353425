import { GetAgentsResponse } from "@/services/get-agents/getAgentsService";
import { GetPromotedAgentsRequest } from "@/services/get-promoted-agents/getPromotedAgentsService";

export function useGetPromotedAgents() {
  const getPromotedAgents = async (req: GetPromotedAgentsRequest) => {
    const result = await fetch(`/api/get-promoted-agents`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data } = await result.json();
    const resultJson = data as GetAgentsResponse[];
    return resultJson;
  };
  return getPromotedAgents;
  
}
