import React, { FunctionComponent, useEffect, useState } from "react";
import BreadcrumbsProps, { IBreadcrumb } from "./BreadcrumbsProps";
import UIText from "../text/UIText";
import Icon from "../icon/icon";
import IBreadcrumbs from "./BreadcrumbsProps";

const breadcrumbsStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  justifyContent: "center",
  alignItems: "center",
};

const breadcrumbStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  gap: "4px",
  justifyContent: "center",
  alignItems: "center",
};

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ options = [], color = 'red4' }) => {
  const [optionsList, setOptionsList] = useState<IBreadcrumb[]>([]);

  useEffect(() => {
    //console.log(options);
    setOptionsList(options);
  }, [options]);

  const lastChild = (index: number): boolean => {
    return index + 1 < optionsList.length
  }
  return (
    <div style={breadcrumbsStyles}>
      {optionsList && optionsList.length > 0 && optionsList.map((option, index) => {
        const { label, link, icon, onClick } = option;
        return (
          <React.Fragment key={label || index}>
            <div style={breadcrumbStyles} onClick={onClick}>
              {icon}
              {label !== undefined &&
                <UIText text={label} link={link} color={lastChild(index) ? "gray600" : color} weight={lastChild(index) ? 'medium' : 'semibold'} />}
            </div>
            {lastChild(index) && (
              <Icon name="KeyboardArrowRight" />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
