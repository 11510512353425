import { SessionInfo } from "@/context/currentSessionContext";

enum ASIRoles {
    Suc = 'Emp_Suc',
    Agente = 'Emp_Agente',
    Promotor = 'Promotor',
    Colaborador = 'Colaborador',
    Sucursal = 'Emp_Sucursal',
}


const sucursalType = [ASIRoles.Suc.toUpperCase(), ASIRoles.Sucursal.toUpperCase()];
const agenteType = [ASIRoles.Agente.toUpperCase()];
const promotorType = [ASIRoles.Promotor.toUpperCase()];
const isSucursal = (role?: string) => {
    if (!role) return false;
    return sucursalType.includes(role.toUpperCase());
}

const isPromotor = (role?: string) => {
    if (!role) return false;
    return promotorType.includes(role.toUpperCase());
}

const isAgent = (role?: string) => {
    if (!role) return false;
    return agenteType.includes(role.toUpperCase());
}
const isRoleSucOrPromotor = (session?: SessionInfo) =>{
    const _isAgent = isSucursal(session?.user?.role?.toUpperCase());
    const _isPromotor = isPromotor(session?.user?.role?.toUpperCase());
    return (_isAgent || _isPromotor);
}

export default ASIRoles;
export { isSucursal, isPromotor , isRoleSucOrPromotor};