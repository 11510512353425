import React, { FunctionComponent } from "react";
import IcomoonReact from "icomoon-react";
import iconSet from "@/assets/fonts/selection.json";
import { IconName } from "@/utils/icons";

interface IconProps {
    color?: string,
    size: string | number,
    icon: IconName,
    className?: string
    style?: React.CSSProperties;
}

const UIIcon: FunctionComponent<IconProps> = ({ className, color, size, icon, style }) => {
    return <IcomoonReact
        className={className}
        iconSet={iconSet}
        color={color}
        size={size}
        icon={icon}
        style={style}
    />;
};

export default UIIcon;
