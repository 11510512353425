import { CancelRegistrationRequest } from "@/services/cancel-registration/cancelRegistrationService";
import { DuplicateComparasonData, DuplicateComparasonRequest } from "@/services/duplicate-comparision/duplicateComparisonService";
import { retryComparasionRequest, retryComparisonResponse } from "@/services/retry-comparison/retryComparisonService";

export function useComparisionManagement() {
  const cancelComparison = async (req: CancelRegistrationRequest) => {
    const result = await fetch(`/api/cancel-registration`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data } = await result.json();
    return data;
  }
  const retryComparison = async (req: retryComparasionRequest) => {
    const result = await fetch(`/api/retry-comparision`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data } = await result.json();
    const resultJson = data as retryComparisonResponse;
    return resultJson;
  };

  const duplicateComparison = async (req: DuplicateComparasonRequest): Promise<DuplicateComparasonData | {message: string}> => {
    const result = await fetch(`/api/duplicate-comparision`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data, error } = await result.json();
    const resultJson = data as DuplicateComparasonData;
    const errorJson = error as {message: string};
    return resultJson || errorJson;
  }

  return { cancelComparison, retryComparison, duplicateComparison };
}