import React, { useState, useMemo, useCallback } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { usePathname, useRouter } from 'next/navigation';
import { signOut, useSession } from 'next-auth/react';
import Image from 'next/image';

import LogOutIcon from '@/assets/icons/log-out.svg';
import SearchIcon from '@/assets/icons/search-md.svg';
import UserPlusIcon from '@/assets/icons/user-plus.svg';
import UserCheckedIcon from '@/assets/icons/user-checked.svg';

import { User } from '@/services/get-current-user/getCurrentUserService';
import { useCurrentSessionContext } from '@/context/currentSessionContext';
import SearchBox from '@/components/SearchBox';
import UIText from '@/components/text/UIText';
import colors from '@/utils/colors';
import styles from './UISideBar.module.css';
import UIWhatsApp from '../whatsapp-box/UIWhatsApp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface UISideBarProps {
  open: boolean;
  version: string;
}

interface MenuOption {
  key: string;
  text?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  route?: string;
  children?: React.ReactNode;
}

const phoneText = "55-3880-1395";
const guideURL = process.env.GUIDE || 'https://portalint.aserta.com.mx/es/cursos-asi';

const handleWhatsAppClick = () => {    
  const atomPlugin = document.querySelector('atom-plugin-web'); // Seleccionar el componente web
    if (atomPlugin && atomPlugin.shadowRoot) {
      const messengerButton = atomPlugin.shadowRoot.getElementById('messenger-button');
      if (messengerButton) {
        messengerButton.click(); // Hacer clic automáticamente
      }
    }
  // const phone = "5215538801395";
  // const textInitial = "Ayuda así";
  // const encodedText = encodeURIComponent(textInitial);
  // const whatsappUrl = process.env.WHATSAPP_URL || `https://api.whatsapp.com/send/?phone=${phone}&text=${encodedText}`;
  // window.open(whatsappUrl, '_blank');
};

const UISideBar: React.FC<UISideBarProps> = (
  { open, version },
) => {
  
  const { data: sessionData, status } = useSession();
  const { setSession, session } = useCurrentSessionContext();
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const router = useRouter();
  const pathname = usePathname();

  const user = sessionData?.user as User;

  const handleOnClickSearch = useCallback(() => {
    if (searchBoxValue.trim()) {
      router.push(`/?search=${encodeURIComponent(searchBoxValue.trim())}`);
      setSearchBoxValue('');
    }
  }, [searchBoxValue, router]);

  const handleSignOut = useCallback(() => {
    setSession(undefined);
    signOut();
  }, [setSession]);
 

  const handleGuideClick = () => {
    window.open(guideURL, '_blank');
  };
  // debugger;  
  const menuOptions: Array<MenuOption | undefined> = useMemo(() => {
      /*const usersAllow = process.env.NEXT_PUBLIC_ALLOWED_USERS?.split(',');
      const isUserEmissionAllowed = usersAllow?.includes(session?.user?.login ?? '');*/
      const permissions = session?.permissions ?? [];
      const isUserEmissionAllowed = permissions.includes("emision");
      
      return [
        {
          key: 'Busqueda',
          children: open ? (
            <Box sx={{ px: 2, py: 1, width: '100%' }}>
              <SearchBox
                classes={{
                  paper: styles.SearchBoxRoot,
                  searchIcon: styles.SearchBoxSearchIcon,
                  inputBase: styles.SearchBoxInputBase,
                }}
                label="Buscar"
                placeholder="Buscar póliza, factura, cliente"
                value={searchBoxValue}
                onChange={setSearchBoxValue}
                onClickSearch={handleOnClickSearch}
                withoutButton
              />
            </Box>
          ) : (
            <Tooltip title="Buscar">
              <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={handleOnClickSearch}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          ),
        },
        { text: 'Altas en proceso', icon: UserPlusIcon, key: 'Altas en proceso', route: '/' },
        (process.env.NEXT_PUBLIC_SHOW_EMISION === 'true' || isUserEmissionAllowed) ? { text: 'Mis clientes', icon: UserCheckedIcon, key: 'Mis clientes', route: '/emission' } : undefined,
      ].filter(item => item !== undefined);
  }, [open, searchBoxValue, handleOnClickSearch, session]);

  const renderMenuOption = useCallback((option: MenuOption | undefined) => (
    !option ? <></> :
    <ListItem
      key={option.key}
      disablePadding
      sx={{
        width: '100%',
        justifyContent: open ? 'flex-start' : 'center',
      }}
    >
      {option.text ? (
          <ListItemButton
            sx={{
              justifyContent: open ? 'flex-start' : 'center',
              width: '100%',
              px: open ? 2 : 1,
              py: 1,
            }}
            onClick={() => option.route && router.push(option.route)}
          >
            {option.icon && (
              <ListItemIcon sx={{ mr: open ? 2 : 0, minWidth: 0 }}>
                <option.icon />
              </ListItemIcon>
            )}
            {open && <UIText text={option.text} color="white" />}
          </ListItemButton>
      ) : (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: open ? 'flex-start' : 'center' }}>
          {option.children}
        </Box>
      )}
    </ListItem>
  ), [open, router]);

  const renderUserInfo = useMemo(() => (
    <Stack direction="row" spacing={open ? 2 : 0} sx={{ justifyContent: open ? 'flex-start' : 'center', alignItems: 'center', p: 2 }}>
      <Avatar sx={{ height: 35, width: 35 }} />
      {open && (
        <Stack width={150} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography noWrap sx={{ color: colors.gray100 }}>
            {session?.user?.raw?.[0].nombreUsuario}
          </Typography>
          <Typography sx={{ color: colors.gray100 }}>{user?.id}</Typography>
          <Tooltip title="Cerrar sesión" placement="right">
            <IconButton
              onClick={handleSignOut}
            >
              <LogOutIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  ), [open, session, user, handleSignOut]);

  if (pathname === '/contract') return null;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        bgcolor: colors.blue1,
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: open ? 'flex-start' : 'center',
            p: 2,
          }}
        > 
          {version && (
            <Tooltip title={`Versión: ${version}`} placement="right">
              <Typography
                variant={open ? "subtitle2" : "caption"}
                sx={{
                  color: colors.gray100,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: open ? 'auto' : '100%',
                  textAlign: open ? 'left' : 'center',
                  mb: 2,
                }}
              >
                {open ? `Versión: ${version}` : version}
              </Typography>
            </Tooltip>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: open ? 'flex-start' : 'center',
              width: '100%',
            }}
          >
            <Image src="/assets/logos/aserta_smile.svg" alt="logo" width={40} height={40} />
            {open && (
              <Typography variant="h6" sx={{ color: colors.gray100, ml: 2 }}>
                Aserta
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <List
        sx={{
          height: 'calc(100vh - 180px)',
          p: 0,
          m: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: open ? 'flex-start' : 'center',
          overflowY: 'auto',
          overflowX: 'hidden',
          width: '100%',
        }}
      >
        {menuOptions.map(renderMenuOption)}
      </List>
      <UIWhatsApp open={open}
        onWhatsAppClick={handleWhatsAppClick}
        bgColor="#FFF"
        hoverBgColor="#FFF"
        icon={<Image src="/assets/icons/asi.svg" alt={"Icono soporte"} width="38" height="38" />}
        text={
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}>
            <UIText
              text="Chat de soporte"
              color="white"
              size="text-s"
              weight="semibold"
            />
            
          </Box>
        }
        textHint=""
        key={"soportefabicon"}
        sx={{ mb: 3, textdecoration: 'none' }}
         /> 
      <UIWhatsApp open={open}
        onWhatsAppClick={handleGuideClick}
        bgColor="#F2F4F7"
        hoverBgColor="#E4E7EC"
        icon={<InfoOutlinedIcon sx={{ color: '#566680' }} />}
        text={
          <UIText
            text="Tutoriales"
            color="white"
            size="text-s"
            weight="semibold"
          />
        }
        textHint="Tutoriales"
        key={"guidefabicon"}
        sx={{ mb: 3, textdecoration: 'none' }} />
      <Paper sx={{ width: '100%', bgcolor: 'inherit' }} elevation={0}>
        {renderUserInfo}
      </Paper>
    </Box>
  );
};

export default React.memo(UISideBar);
export { handleWhatsAppClick }
