import React, { useMemo } from "react";
import { Chip, ChipProps, styled } from "@mui/material";
import colors from "@/utils/colorsV2";
import UIText from "../text/UIText";
import UIChipProps from "./UIChipProps";
import UITextProps from "../text/UITextProps";

type ChipType = UIChipProps['type'];
type TextColorType = UITextProps['color'];

const colorStyles: Record<NonNullable<ChipType>, {
  backgroundColor: string;
  borderColor: string;
  color: string;
}> = {
  success: {
    backgroundColor: colors.green2,
    borderColor: colors.green1,
    color: colors.success700
  },
  primary: {
    backgroundColor: colors.grayLM50,
    borderColor: colors.grayLM200,
    color: colors.grayLM700
  },
  secondary: {
    backgroundColor: colors.asertaOrange,
    borderColor: colors.asertaOrange,
    color: colors.grayLM700
  },
  info: {
    backgroundColor: colors.blue4,
    borderColor: colors.blue3,
    color: colors.blue5
  },
  error: {
    backgroundColor: colors.red2,
    borderColor: colors.red1,
    color: colors.red3
  },
  warning: {
    backgroundColor: "#FFFAEB",
    borderColor: "#FEDF89",
    color: "#F79009"
  }
};

interface ColorChipProps extends ChipProps {
  $hasBullet?: boolean;
}

const ColorChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== '$hasBullet',
})<ColorChipProps>(({ color = "primary", $hasBullet }) => ({
  ...(colorStyles[color as keyof typeof colorStyles] || colorStyles.primary),
  fontSize: "12px",
  height: "24px",
  ...($hasBullet && {
    width: "calc(8ch + 40px)", // Ancho fijo solo para chips con bullet
  }),
  "& .MuiChip-icon": {
    order: 1,
    marginLeft: "-4px",
    marginRight: "4px",
    fontSize: "14px",
  },
  "& .MuiChip-label": {
    padding: "0 5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...($hasBullet ? {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    } : {
      whiteSpace: "normal",
      height: "100%",
    }),
  },
}));

const textColors: Record<NonNullable<ChipType>, TextColorType> = {
  success: "success",
  primary: "gray",
  secondary: "white",
  info: "blue5",
  error: "red3",
  warning: "warning"
};

const UIChip: React.FC<UIChipProps> = ({ type = "primary", label, icon, sxText }) => {
  const hasBullet = typeof label === 'string' && label.includes('•');

  const formattedLabel = useMemo(() => {
    if (hasBullet) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
          <span style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{(label as string).slice(1)}</span>
        </span>
      );
    }
    return label;
  }, [label, hasBullet]);

  const textColor = textColors[type] || textColors.primary;

  return (
    <ColorChip
      label={
        <UIText
          text={formattedLabel}
          size="text-xs"
          color={textColor}
          sx={{ width: '100%', textAlign: 'center', ...sxText }}
        />
      }
      icon={icon}
      variant="outlined"
      color={type}
      $hasBullet={hasBullet}
    />
  );
};

export default React.memo(UIChip);